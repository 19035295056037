<template>
    <div class="article-panel taskdata-panel task-comment-panel">
        <div class="taskdata-panel-inner">
            <h3 class="g-title">项目评价</h3>
            <div class="article-bd">
                <div class="task-info">
                    <div class="taskinfo-l">
                        <div class="tit-box">
                            <span class="status-box"
                                  style="background-color: #8c8c8c;">{{trade.status_value}}</span>
                            <h4 class="tit"
                                v-text="task.task_name"></h4>
                        </div>
                        <div class="num-box">
                            <div class="time-box">
                                <span class="desc">
                                    <i class="el-icon-time"></i>
                                    发布时间：
                                    <i class="num">{{task.approve_time}}</i>
                                </span>
                            </div>

                            <div class="reword-box">
                                <div class="reword-item">
                                    <span class="desc">酬金：</span>
                                    <b class="num">￥{{trade.remuneration}}</b>
                                </div>
                                <div class="reword-item"
                                     v-if="task && task.task_type==3">
                                    <span class="desc">工期：</span>
                                    <b class="num">{{trade.work_time}}</b>
                                </div>
                                <div class="reword-item"
                                     v-if="task && task.task_type==3">
                                    <span class="desc">每天酬金：</span>
                                    <b class="num">￥{{(trade.remuneration/trade.work_time).toFixed(2)}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="userinfo-box">
                            <div class="user-r">
                                <div class="desc-item">项目类别：{{task.task_type_value}}</div>
                                <div class="desc-item">服务分类：{{task.speciality_type_value}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="taskinfo-r" v-if="trade && trade.geek">
                        <div class="user-info-box"
                             v-if="userInfo.info.user_type==1">
                            <div class="avatar">
                                <el-image :src="util.reImg(task.employer.avatar)"
                                          :fit="'fill'">
                                    <div class="err-slot image-slot"
                                         slot="error">
                                        <span class="placeholder"></span>
                                    </div>
                                </el-image>
                                <div class="avatar-r">
                                    <p class="desc">{{task.employer.name}}</p>
                                    <span class="desc">{{task.employer.area_value}}</span>
                                    <p class="desc">完成时间：2019-10-25</p>
                                </div>
                            </div>
                            <div class="user-info-ft">
                                <el-button type="primary"
                                           @click="employerDetail(task.employer.uk)">查看资料</el-button>
                                <el-button type="primary"
                                           @click="sendMessage(task.employer.uk)">联系Ta</el-button>
                            </div>
                        </div>
                        <div class="user-info-box"
                             v-else>
                            <div class="avatar">
                                <el-image :src="util.reImg(trade.geek.avatar)"
                                          :fit="'fill'">
                                    <div class="err-slot image-slot"
                                         slot="error">
                                        <span class="placeholder"></span>
                                    </div>
                                </el-image>
                                <div class="avatar-r">
                                    <p class="desc">{{trade.geek.name}}</p>
                                    <span class="desc">{{trade.geek.area_value}}</span>
                                    <p class="desc">完成时间：2019-10-25</p>
                                </div>
                            </div>
                            <div class="user-info-ft">
                                <el-button type="primary"
                                           @click="geekDetail(trade.geek.uk)">查看资料</el-button>
                                <el-button type="primary"
                                           @click="sendMessage(trade.geek.uk)">联系Ta</el-button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="tips-box" v-if="userInfo.info.user_type==1">
                    <span class="tip-text">请对雇主的服务作出评价，评价将综合计算并转化为用户诚信评分，双方互评结束即为项目正式完成。</span>
                </div>
                <div class="tips-box" v-else>
                    <span class="tip-text">请对极客的工作作出评价，评价将综合计算并转化为用户诚信评分，双方互评结束即为项目正式完成。</span>
                </div>-->

                <div class="comment-wraper"
                     style="font-size: 12px;"
                     v-if="userInfo.info.user_type==1">
                    <div class="comment-item">
                        需求明确：
                        <el-rate v-model="commentFormData.demand_score"
                                 show-score
                                 text-color="#ef8000"
                                 score-template="{value}"></el-rate>
                    </div>
                    <div class="comment-item">
                        支付速度：
                        <el-rate v-model="commentFormData.pay_score"
                                 show-score
                                 text-color="#ef8000"
                                 score-template="{value}"></el-rate>
                    </div>
                    <div class="comment-item">
                        对接态度：
                        <el-rate v-model="commentFormData.dock_score"
                                 show-score
                                 text-color="#ef8000"
                                 score-template="{value}"></el-rate>
                    </div>
                    <div class="comment-cont">
                        <el-input type="textarea"
                                  placeholder="请输入评价内容"
                                  v-model="commentFormData.geek_content">
                        </el-input>
                        <div style="width:100%;text-align:center;height:80px;line-height:80px;">
                            <el-button type="primary"
                                       class="submit-btn"
                                       @click="save">确认评价
                            </el-button>
                            <el-button @click="goBack">返回</el-button>
                        </div>
                    </div>
                </div>

                <div class="comment-wraper"
                     style="font-size: 12px;"
                     v-else>
                    <div class="comment-item">
                        工作态度：
                        <el-rate v-model="commentFormData.attitude_score"
                                 show-score
                                 text-color="#ef8000"
                                 score-template="{value}"></el-rate>
                    </div>
                    <div class="comment-item">
                        工作速度：
                        <el-rate v-model="commentFormData.speed_score"
                                 show-score
                                 text-color="#ef8000"
                                 score-template="{value}"></el-rate>
                    </div>
                    <div class="comment-item">
                        工作质量：
                        <el-rate v-model="commentFormData.quality_score"
                                 show-score
                                 text-color="#ef8000"
                                 score-template="{value}"></el-rate>
                    </div>
                    <div class="comment-cont">
                        <el-input type="textarea"
                                  placeholder="请输入评价内容"
                                  v-model="commentFormData.employer_content">
                        </el-input>

                        <div style="width:100%;text-align:center;height:80px;line-height:80px;">
                            <el-button type="primary"
                                       class="submit-btn"
                                       @click="save">确认评价
                            </el-button>

                            <el-button @click="goBack">返回</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "TaskAppraise",
    metaInfo() {
        return {
            title: "项目评价 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    components: {},
    data() {
        return {
            commentFormData: {
                demand_score: 4, // 极客对雇主评价 - 需求明确
                pay_score: 3, // 极客对雇主评价 - 支付速度
                dock_score: 2, // 极客对雇主评价 - 对接态度
                geek_content: "", // 极客对雇主评价 - 评论内容

                attitude_score: 4, // 雇主评价极客 - 工作态度
                speed_score: 3, // 雇主评价极客 - 工作速度
                quality_score: 2, // 雇主评价极客 - 工作质量
                employer_content: "" // 极客对雇主评价 - 评论内容
            },
            task: "",
            trade: ""
        };
    },

    computed: {
        ...mapState(["userInfo"])
    },

    methods: {
        //获取项目信息
        loadData: function() {
            let _this = this;
            _this.post(
                "/task/task/detail_todo",
                { tk: _this.$route.query.tk },
                res => {
                    if (res.code == 200) {
                        _this.task = res.data;
                        _this.trade = res.data.trade;
                    } else {
                        if (res.msg) {
                            _this.$message.success(res.msg);
                        } else {
                            _this.$message.success("获取失败");
                        }
                    }
                }
            );
        },

        //发送消息
        sendMessage(val) {
            //侧边栏是否保持展开
            if (val && val.isShowDraw == "isShowDraw") {
                sessionStorage.setItem("isShowDraw", true);
            }
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/user/message/sendmsg?uk=" + val);
        },

        //查看极客详情
        geekDetail(uk, isShowDraw = "") {
            //侧边栏是否保持展开
            if (isShowDraw) {
                sessionStorage.setItem("isShowDraw", true);
            }
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/firm/index?view=1&uk=" + uk);
        },

        employerDetail(uk, isShowDraw = ""){
            //侧边栏是否保持展开
            if (isShowDraw) {
                sessionStorage.setItem("isShowDraw", true);
            }
            sessionStorage.setItem(
                "currentPageUrl",
                document.location.pathname + document.location.search
            ); //存储当前值
            this.$router.push("/firm/employer?&uk=" + uk);
        },

        //返回
        goBack() {
            this.$router.go(-1);
        },

        //添加评价信息
        save() {
            let _this = this;
            let content = "";
            if (this.userInfo.info.user_type == 1) {
                content = _this.commentFormData.geek_content;
            } else {
                content = _this.commentFormData.employer_content;
            }

            if (!content) {
                _this.$message.error("评价内容不能为空");
                return false;
            }

            _this.commentFormData.tk = _this.$route.query.tk;

            this.$confirm(
                "提交评价信息后将不可更改，且只能评价一次，是否继续?",
                "温馨提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }
            )
                .then(() => {
                    _this.post(
                        "/task/task/task_appraise",
                        _this.commentFormData,
                        function(res) {
                            if (res.code == 200) {
                                _this.$message.success("评价成功");
                                if (_this.userInfo.user_type == 1) {
                                    _this.$router.push(
                                        "/user/task/geek?tk=" +
                                            _this.$route.query.tk
                                    );
                                } else {
                                    _this.$router.push(
                                        "/user/task/employer?tk=" +
                                            _this.$route.query.tk
                                    );
                                }
                            } else {
                                if (res.msg) {
                                    _this.$message.error(res.msg);
                                } else {
                                    _this.$message.error("评价失败");
                                }
                            }
                        }
                    );
                })
                .catch(() => {});
        }
    },
    created() {
        this.loadData();
    }
};
</script>
<style lang="less" scoped>
.taskdata-panel .data-list-wraper {
    margin-top: -6px;
}
.comment-wraper {
    margin-top: -1px;
    padding: 15px;
    border: 1px solid #e6e6e6;
}
.comment-item {
    font-size: 16px;
}
.comment-item {
    margin: 30px 0;
}
.comment-cont {
    .el-textarea,
    /deep/.el-textarea__inner {
        min-height: 180px !important;
        border-color: #dcdfe6;
    }
}
.submit-btn {
    margin: 10px 0 50px;
}
</style>
<style lang="less">
@import "../../../styles/taskData.less";
.task-comment-panel {
    .task-info {
        .taskinfo-l,
        .taskinfo-r {
            height: 200px;
        }
    }
    .el-rate {
        margin: 10px 20px;
    }
    .el-rate__icon {
        font-size: 28px;
    }
}
</style>